<template>
  <v-app>
    <v-card
      class="my-base-vcard-style"
      elevation="0"
    >
      <br>
      <br>
      <v-row
        class="my-style"
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="6"
        >
          <v-card-text class="text-left">
            <img
              :src="logo"
              width="100px"
            >
            <br>
            <br>
            <h2 class="title-style">
              Create New Password
            </h2><br><br>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="password"
                label="New Password*"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="passwordRules"
                outlined
                required
                dense
                @click:append="showPassword = !showPassword"
              />
              <v-text-field
                v-model="confirmPassword"
                label="Confirm Password*"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                outlined
                required
                dense
                @click:append="showConfirmPassword = !showConfirmPassword"
              />
            </v-form>
            <v-btn
              class="mt-2"
              color="#000"
              @click="newPassword()"
            >
              Confirm
            </v-btn>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <dashboard-core-footer />
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import { cdnUrl } from 'src/app-globals';

  export default {
    name: 'CreateNewPassword',
    components: {
      'centre-spinner': spinner,
      DashboardCoreFooter: () => import('../dashboard/components/core/Footer'),
    },
    data () {
      return {
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        valid: false,
        loading: false,
        passwordRules: [v => (v || '').length >= 8 || 'Not strong.Requires at least 8 characters.'],
        confirmPasswordRules: [v => !!v || 'This field is required'],
        logo: `${cdnUrl}/website/NewDBSLogo.png`,
      };
    },
    computed: {
      linkActiveId () {
        return this.$store.getters['passwordreset/getLinkActiveId'];
      },
      userId () {
        return this.$store.getters['passwordreset/getExternalId'];
      },
      passwordConfirmationRule () {
        return this.password === this.confirmPassword || 'Password must match';
      },
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (Object.keys(vm.linkActiveId).length === 0) {
          vm.$router.push({ name: 'Login' });
        } else {
          next();
        }
      });
    },
    methods: {
      newPassword () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('passwordreset/createNewPassword', {
          link_active_id: this.linkActiveId.link_active_id,
          user_id: this.userId.id,
          password: this.password,
          password_confirmed: this.passwordConfirmationRule,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Password updated successfully',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$router.push({ name: 'Login' });
        }).finally(
          this.loading = false,
          this.$store.dispatch('passwordreset/clearLinkActiveId'),
          this.$store.dispatch('passwordreset/clearExternalId'),
        );
      },
    },
  };
</script>
<style scoped>
.my-style {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 50px;
}
.title-style {
  color: #000;
  text-align: left;
  font-size: 35px;
}
.icon-style {
  color: #000;
  font-size: 45px;
}
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  border-radius: 15px;
}
.my-base-vcard-style {
  height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
}
.v-btn{
  width: 100%;
}
.v-card{
  align-items: center;
  justify-content: center;
}
</style>
