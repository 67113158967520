<template>
  <v-overlay
    :absolute="absolute"
    :opacity="opacity"
    :value="loading"
    :z-index="zIndex"
  >
    <v-progress-circular
      indeterminate
      :color="spinnerColor"
    />
  </v-overlay>
</template>
<script>
  export default {
    name: 'SpinnerCenter',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      spinnerColor: {
        type: String,
        default: '#FF3700',
      },
    },
    data: () => ({
      absolute: false,
      opacity: 0.46,
      zIndex: 5,
    }),
  };
</script>
